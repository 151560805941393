/**
 * @param target スクロール先の要素
 */
function scroll(target: Element) {
  let scrollTop = window.scrollY + target.getBoundingClientRect().top

  if (document.body.classList.contains('fixed-header')) {
    scrollTop -= document.querySelector('header').getBoundingClientRect().height
  }

  window.scroll({ top: scrollTop, behavior: "smooth" })
}

export function init() {
  document.querySelectorAll('a').forEach(anchor => {
    if (anchor.pathname === window.location.pathname && anchor.hash !== "") {
      anchor.onclick = e => {
        e.preventDefault()

        scroll(document.querySelector(anchor.hash))
      }
    }
  })

  window.onload = (e: Event) => {
    const hash = window.location.hash

    if (hash !== '') {
      scroll(document.querySelector(hash))
    }
  }
}
