function collapse(target: HTMLElement) {
  target.style.blockSize = '0'
}

function expand(target: HTMLElement) {
  target.style.blockSize = `${target.dataset.initialHeight}px`
}

function resetInitialHeight(container: HTMLElement) {
  container.querySelectorAll<HTMLElement>('.expandable-trigger').forEach(trigger => {
    const target = document.getElementById(trigger.dataset.target)
    const content = target.querySelector('.expandable-target-content')

    target.dataset.initialHeight = `${content.scrollHeight}`
  })
}

export function init(listSelector = ".expandable-container") {
  document.querySelectorAll<HTMLElement>(listSelector).forEach(container => {
    resetInitialHeight(container)
    window.addEventListener('resize', () => {
      console.clear()
      resetInitialHeight(container)
    })

    container.querySelectorAll<HTMLElement>('.expandable-trigger').forEach(trigger => {
      const target = document.getElementById(trigger.dataset.target)

      if (target) {
        target.style.blockSize = '0'

        trigger.addEventListener('click', () => {
          if (target.classList.toggle('expanded')) {
            expand(target)
          } else {
            collapse(target)
          }
        })
      }
    })
  })
}
