import * as lightbox from "lightbox2"
import * as mv from "modules/mv";
import * as gallery from "modules/gallery";
import * as popup from "modules/popup";
import * as smoothScroll from "modules/smooth-scroll";
import * as expandable from "modules/expandable";
import SPMenu from "modules/sp-menu";

lightbox.option({
  albumLabel: '%1 / %2',
  fadeDuration: 500,
  imageFadeDuration: 500,
  resizeDuration: 500,
})

document.addEventListener('DOMContentLoaded', () => {
  document.body.classList.toggle('fixed-header', document.documentElement.clientWidth <= 576)

  window.addEventListener('resize', () => {
    document.body.classList.toggle('fixed-header', document.documentElement.clientWidth <= 576)
  })

  mv.init(".mv-slider")
  gallery.init()
  popup.init()
  smoothScroll.init()
  expandable.init()

  new SPMenu(".sp-menu", ".sp-menu-opener", ".sp-menu-closer")

  // フォームの隠しボックス開閉処理
  $('form input[type=radio]').on('change', function (this: HTMLInputElement) {
    const target = $(this).closest('.form-group').find('.hidden-box')

    if (Number(this.value) === target.data('display-value')) {
      target.slideDown('fast')
    } else {
      target.slideUp('fast')
    }
  })
})
