import Swiper from "swiper"
import { Autoplay, Pagination, EffectFade } from "swiper/modules"
import { CSSSelector } from "swiper/types"

let swiper: Swiper

/**
 * @param container
 */
export function init(container: HTMLElement | CSSSelector) {
  swiper = new Swiper(container, {
    modules: [Autoplay, Pagination, EffectFade],
    effect: "fade",
    speed: 3000,
    autoplay: { delay: 5000 },
    pagination: {
      el: ".pagination",
      bulletClass: "bullet",
      bulletActiveClass: "active"
    }
  })
}

export function swiperObject() { return swiper }
