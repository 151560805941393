/**
 * @param galleriesSelector
 */
export function init(galleriesSelector = '.gallery') {
  const galleries = document.querySelectorAll(galleriesSelector)

  galleries.forEach(gallery => {
    gallery.querySelectorAll<HTMLElement>('.thumbnail').forEach(thumbnail => {
      thumbnail.onclick = () => {
        // サムネイル切り替え
        const beforeThumbnail = thumbnail.closest('.thumbnails').querySelector('.active')
        beforeThumbnail.classList.remove('active')
        thumbnail.classList.add('active')

        // 画像切り替え
        const pictureFrame = thumbnail.closest('.gallery').querySelector('.frame')
        const beforePicture = pictureFrame.querySelector('.picture.active')
        const afterPicture = pictureFrame.querySelector(`.picture[data-index="${thumbnail.dataset.index}"]`)
        beforePicture.classList.remove('active')
        afterPicture.classList.add('active')
      }
    })
  })
}
