export default class SPMenu {
  #menuElement: HTMLElement = null

  /**
   * @param menu メニューの要素を表すセレクタ文字列
   * @param opener クリックしてメニューを開くための要素を表すセレクタ文字列
   * @param closer クリックしてメニューを閉じるための要素を表すセレクタ文字列
   */
  constructor(menu: string, opener: string, closer: string) {
    this.#menuElement = document.querySelector(menu)

    document.querySelector<HTMLElement>(opener).onclick = () => { this.open() }
    document.querySelector<HTMLElement>(closer).onclick = () => { this.close() }

    this.#menuElement.querySelectorAll('a').forEach(item => {
      item.addEventListener('click', () => { this.close() })
    })
  }

  /**
   * メニューを開く
   */
  open() {
    document.body.classList.add('scroll-lock')
    this.#menuElement.classList.add('opening')
  }

  /**
   * メニューを閉じる
   */
  close() {
    document.body.classList.remove('scroll-lock')
    this.#menuElement.classList.remove('opening')
  }
}
