function _initHeaderNavPopup() {
  document.querySelectorAll('header nav ul > li.has-submenu').forEach((item: HTMLLIElement) => {
    const submenu = item.querySelector<HTMLElement>('.submenu')

    submenu.inert = true

    item.onmouseenter = () => {
      item.classList.add('active-submenu')
      submenu.inert = false
    }

    item.onmouseleave = () => {
      item.classList.remove('active-submenu')
      submenu.inert = true
    }
  })
}

function _initButtonPopup() {
  document.querySelectorAll('.button-group.has-popup > .button').forEach((button: HTMLElement) => {
    button.onmouseenter = () => {
      button.closest('.button-group').classList.add('active-popup')
    }
  })

  document.querySelectorAll('.button-group').forEach((group: HTMLElement) => {
    group.onmouseleave = () => {
      group.classList.remove('active-popup')
    }
  })
}

export function init() {
  _initHeaderNavPopup()
  _initButtonPopup()
}
